/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "./assets/ontario-design-system/styles/ds-theme.css";


html {
  overflow-x: hidden;
}

.row {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: 72rem;
  width: 100%;

  @media print, screen and (min-width: 73rem) {
    flex-direction: row;
  }


  .column, .columns {
    padding-right: .625rem;
    padding-left: .625rem;

    @media print, screen and (min-width: 40em) {
      padding-right: .9375rem;
      padding-left: .9375rem;
    }
  }
}

.border-left {
  border-left: 1px solid #2E2E2E;
}

.border-left-white {
  border-left: 1px solid #FFFFFF;
}

.no-bottom-margin {
  margin-bottom: 0;
}

// .ontario-row {
//   max-width: 97rem;
// }

.border-bottom {
  border-bottom: 1px solid #1A1A1A;
}

.text-content-smallest {
  font: normal normal normal 15px/26px Open Sans;
  letter-spacing: 0px;
  //color: #0D0D0D;
  white-space: pre-line;
}

.text-content-smaller {
  font: normal normal normal 16px/26px Open Sans;
  letter-spacing: 0px;
  //color: #0D0D0D;
  white-space: pre-line;
}

.text-content-larger {
  font: normal normal normal 18px/28px Open Sans;
  letter-spacing: 0px;
  //color: #0D0D0D;
  opacity: 1;
  white-space: pre-line;
}


.grey-box-header {
  background: #4D4D4D 0% 0% no-repeat padding-box;
  min-height: 50px;
  max-height: 85px;
  text-align: left;
  font: normal normal 600 20px/56px Open Sans;
  letter-spacing: 0px;
  color: #FFFFFF;
  line-height: 150%;
  padding-top: 0.7rem;
  padding-bottom: 0rem;
}

.grey-box-body {
  background: #F6F6F6 0% 0% no-repeat padding-box;
  //color: #0D0D0D;
  box-shadow: none;

  h4 {
    text-align: left;
    font: normal normal 600 18px/56px Open Sans;
    letter-spacing: 0px;
    //color: #0D0D0D;
    line-height: 24px;
    margin-top: 2rem;
    margin-bottom: 1rem;

    &:first-of-type {
      margin-top: 1.5rem;
    }
  }
}

.input-label {
  text-align: left;
  font: normal normal normal 16px/26px Open Sans;
  letter-spacing: 0px;
  //color: #1A1A1A;
}

.section-title {
  text-align: left;
  font: normal normal 600 24px/36px Open Sans;
  letter-spacing: 0px;
  //color: #0D0D0D;
}

.dashed-border-bottom {
  border-bottom: 1px dashed #1A1A1A;
}

.step-title {
  text-align: left;
  font: normal normal 600 32px/56px Open Sans;
  letter-spacing: 0px;
  //color: #0D0D0D;
}

.step-subtext {
  text-align: left;
  font: normal normal normal 16px/26px Raleway;
  letter-spacing: 0px;
 // color: #0D0D0D;
}

.information-panel {
  float: right;
}

.buttons-column {
  float: left !important;
}

.link-like-span {
  cursor: pointer;
  text-align: left;
  text-decoration: underline;
  font: normal normal 600 20px/36px Open Sans;
  letter-spacing: 0px;
  color: #0066CC;
  opacity: 1;
  border: none;
  background: none;
}

.limits-container {
  h4 {
    text-align: left;
    font: normal normal 600 20px/36px Open Sans;
    letter-spacing: 0px;
    //color: #0D0D0D;
    opacity: 1;
    border-bottom: 1px solid #1A1A1A;
  }
}


.option-text a {
  display: contents;
}

@media print {
  .noprint, #ontario-header-default, #start-new-button {
    display: none;
    height: 0px;
  }
}

#sign-up-div, #signed-up-div, .fa-ul {
  li {
    background-image: url("./assets/images/checkmark-blacker.svg");
    background-repeat: no-repeat;
    list-style: none;
    background-position: 0rem 6px;
    background-size: 18px;
    position: relative;
    left: -2rem;
    padding-left: 2rem;
  }
}

.safetyPlan a {
  display: contents !important;
}

.paragraph-body ul {
  margin-top: 6px;
  margin-bottom: 0px;

  li {
    padding-bottom: 6px;
  }
}

.ontario-row .ontario-row {
  width: 100%;
}

p:not(.option-text) {
  margin-bottom: 0rem !important;
}


.fa-ul {
  margin-top: 1rem !important;
}


#ministry-sub-title {
  padding-bottom: 2rem;
}

@media screen and (max-width: 73rem) {
  .ontario-footer__expanded-top-section .ontario-footer__link {
    text-decoration: underline;
    word-break: break-word;
  }

  .ontario-textarea {
    margin-left: 0 !important;
  }
}

app-paragraph {
  @media screen and (max-width: 40rem){
    ul {
      margin-left: 0.5rem;

      li {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.ontario-checkboxes__item {
  word-break: break-word !important;
}

.govuk-tag {
  display: inline-block;
  margin-top: 1rem;
  margin-right: 0.5rem;
  outline: 2px solid rgba(0,0,0,0);
  outline-offset: -2px;
  text-decoration: none;
  // text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 5px;
  padding-right: 8px;
  padding-bottom: 4px;
  padding-left: 8px;
}

.govuk-tag-checkbox {
  display: inline-block;
  margin-top: 0rem;
  margin-right: 0.5rem;
  outline: 2px solid rgba(0,0,0,0);
  outline-offset: -2px;
  text-decoration: none;
  // text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 5px;
  padding-right: 8px;
  padding-bottom: 4px;
  padding-left: 8px;
  white-space: nowrap;
}

.govuk-tag--green {
  color: #005a30;
  background: #cce2d8;
}

.govuk-tag--yellow {
  color: #594d00;
  background: #fff7bf;
}

.govuk-tag--blue {
  color: #144e81;
  background: #d2e2f1;
}

.govuk-tag--red {
  color: #942514;
  background: #f6d7d2;
}

.govuk-tag--orange {
  color: #6e3619;
  background: #fcd6c3;
}

.govuk-tag--pink {
  color: #80224d;
  background: #f7d7e6;
}

.govuk-tag--grey {
  color: #383f43;
  background: #eeefef;
}

.safety-plan-tag-complete {
  width: 100%;
  background-color: #1080a6;
  padding-bottom: 1rem;
  padding-top: 1rem;
  padding-left: 0.75rem;
  margin-bottom: 0.75rem;
}

.safety-plan-tag-incomplete {
  width: 100%;
  background-color: #f2f2f2;
  padding-bottom: 1rem;
  padding-top: 1rem;
  padding-left: 0.75rem;
  margin-bottom: 0.75rem;
}

